import { notNull } from "@warrenio/utils/notNull";
import { useState } from "react";
import { EditableButton } from "../../components/forms/EditableButton.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { CurrentPool } from "../host_pools/CurrentPool.tsx";
import type { AdminHostPoolWithType } from "../host_pools/hostPoolsQuery.ts";
import { PoolSelect } from "../host_pools/PoolSelect.tsx";
import { hostEditMutation } from "./hostQuery.ts";
import type { HostWithStorage } from "./HostsTable.tsx";
import { ModalHeaderBlock } from "./ModalHeaderBlock.tsx";
import { StatusBadge } from "./StatusBadge.tsx";

interface PoolBadgeModalProps {
    item: HostWithStorage;
}

export function PoolBadgeButton(props: PoolBadgeModalProps) {
    const { item } = props;
    return (
        <WModal button={<EditableButton action={undefined}>{item.pool_name}</EditableButton>}>
            <PoolBadgeModalContent {...props} />
        </WModal>
    );
}

function PoolBadgeModalContent({ item }: PoolBadgeModalProps) {
    const [pool, setPool] = useState<string>(item.pool_uuid);
    const updateMutation = useStandardMutation(hostEditMutation);

    async function onSubmit() {
        await updateMutation.mutateAsync({
            body: {
                uuid: item.uuid,
                host_pool_uuid: pool,
            },
            location: item.location,
        });
    }

    function changePool(item: AdminHostPoolWithType) {
        setPool(item.uuid);
    }

    return (
        <WModalContent title="Change Hypervisor Pool" label="Change" modalAction={async () => await onSubmit()}>
            <ModalHeaderBlock item={item}>
                <StatusBadge item={item} />
            </ModalHeaderBlock>

            <div className="pt-3 pb-2">Current pool</div>
            <div className="lightBlock p-3">
                <CurrentPool location={notNull(item.location)} value={notNull(item.pool_uuid)} />
            </div>

            <div className="pt-3 pb-2">Target pool*</div>
            <div>
                <PoolSelect location={notNull(item.location)} valueKey={pool} onChange={(item) => changePool(item)} />
            </div>
        </WModalContent>
    );
}
