import { useState } from "react";
import { EditableButton } from "../../components/forms/EditableButton.tsx";
import { FormField } from "../../components/forms/FormField.tsx";
import { WSelect } from "../../components/forms/WSelect.tsx";
import LR from "../../components/LeftRight.module.css";
import { LeftRight } from "../../components/LeftRight.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { hostEditMutation } from "./hostQuery.ts";
import type { HostWithStorage } from "./HostsTable.tsx";
import { HvOs } from "./HvOs.tsx";
import { ModalHeaderBlock } from "./ModalHeaderBlock.tsx";
import { StatusBadge } from "./StatusBadge.tsx";

interface OsType {
    id: string;
    title: string;
}

const osItems: OsType[] = [
    { id: "multi", title: "Multi" },
    { id: "linux", title: "Linux" },
    { id: "windows", title: "Windows" },
];

interface OsBadgeModalProps {
    item: HostWithStorage;
}

export function OsBadgeButton(props: OsBadgeModalProps) {
    const { item } = props;
    return (
        <WModal
            button={
                <EditableButton action={undefined}>
                    <HvOs value={item.os} />
                </EditableButton>
            }
        >
            <OsBadgeModalContent {...props} />
        </WModal>
    );
}

function OsBadgeModalContent({ item }: OsBadgeModalProps) {
    const [os, setOs] = useState<string>(item.os);
    const updateMutation = useStandardMutation(hostEditMutation);

    async function onSubmit() {
        await updateMutation.mutateAsync({
            body: {
                uuid: item.uuid,
                os: os,
            },
            location: item.location,
        });
    }

    return (
        <WModalContent title="Change Hypervisor OS" label="Change" modalAction={async () => await onSubmit()}>
            <ModalHeaderBlock item={item}>
                <StatusBadge item={item} />
            </ModalHeaderBlock>

            <div>
                <FormField isRequired wide label="OS">
                    <WSelect
                        isRequired
                        getKey={(item) => item.id}
                        items={osItems}
                        itemClassName={LR.item}
                        valueClassName={LR.value}
                        aria-label="Select os"
                        getTextValue={(item) => item.title}
                        valueKey={os}
                        onChange={(item) => setOs(item.id)}
                    >
                        {({ id }) => <LeftRight title={<HvOs value={id} />} />}
                    </WSelect>
                </FormField>
            </div>
        </WModalContent>
    );
}
